import React, { useState, useEffect } from "react";
import All from "../img/all.png";
import U from "../img/Ukrane.jpeg";
import "../Css/ESG.css";

const ESG = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  return (
    <>
      <div className={scrollPosition < 1900 ? "ESG" : "ESG_end"}>
        <div className="ESG-sec">
          <div className="GG-text">
            <div className="G-Collection">
              <div className="img-box">
                <div className="All-img">
                  <img src={All} alt="png" />
                </div>
                {/* <div className="image-text-box">
                  <p>
                    <span>E</span>nvironment: Heaven, Earth, 天地
                    <br />
                    <span>S</span>ocial: Community, 人<br />
                    <span>G</span>overnance: Trust, Responsibility, Rule 律{" "}
                  </p>
                </div> */}
              </div>
              <br />
              <p>
                <span>Genesis Collection, ESG Symbol</span>
                <br />
                Symbols may be interpreted in many ways, but all the same, may
                help a person who works on his or her dreams to better integrate
                various mind levels as well as connect with many common human
                features or experiences. HYA collection starts with the Symbol,
                global 99 artists will reveal symbol works of Heaven, Sky,
                Human, and Rule. Apply to become 99 artists is now available.{" "}
              </p>
              <br />
              <br />
            </div>
            <div>
              <a className="mintBtn2" href="/about">
                Artist Apply
              </a>
            </div>
          </div>

          <div className="GG-text">
            <div className="Gaia-Collection">
              <div className="img-box2">
                <img className="U-img" src={U} alt="png" />
              </div>
              <br />
              <p>
                <span>GAIA Collection, The EARTH </span>
                <br />
                Artworks of four ESG Symbols in city or landscape.
                <br /> Human is Universe We know that true abiding happiness
                cannot exist while others suffer and comes only from serving
                others, living in harmony with nature, and realizing our innate
                wisdom and the true and brilliant nature of our own minds.{" "}
              </p>
              <br />
              <br />
            </div>
            <div>
              <a className="mintBtn2" href="/mint">
                Minting Live
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={scrollPosition < 2500 ? "ESG-M" : "ESG_end-M"}>
        <div className="ESG-sec-M">
          <div className="GG-text-M">
            <div className="G-Collection-M">
              <div className="img-box-M">
                <div className="All-img-M">
                  <img src={All} alt="png" />
                </div>
                {/* <div className="image-text-box-M">
                  <p>
                    <span>E</span>nvironment: Heaven, Earth, 天地
                    <br />
                    <span>S</span>ocial: Community, 人<br />
                    <span>G</span>overnance: Trust, Responsibility, Rule 律{" "}
                  </p>
                </div> */}
              </div>
              <br />
              <p>
                <span>Genesis Collection, ESG Symbol</span>
                <br />
                Symbols may be interpreted in many ways, but all the same, may
                help a person who works on his or her dreams to better integrate
                various mind levels as well as connect with many common human
                features or experiences. HYA collection starts with the Symbol,
                global 99 artists will reveal symbol works of Heaven, Sky,
                Human, and Rule. Apply to become 99 artists is now available.{" "}
              </p>
              <br />
              <br />
            </div>
            <div>
              <a className="mintBtn2-M" href="/about">
                Artist Apply
              </a>
            </div>
          </div>
          <br/>
          <br/>
          <br/>
          <div className="GG-text-M">
            <div className="Gaia-Collection-M">
              <div className="img-box2-M">
                <img className="U-img-M" src={U} alt="png" />
              </div>
              <br />
              <p>
                <span>GAIA Collection, The EARTH </span>
                <br />
                Artworks of four ESG Symbols in city or landscape.
                <br /> Human is Universe We know that true abiding happiness
                cannot exist while others suffer and comes only from serving
                others, living in harmony with nature, and realizing our innate
                wisdom and the true and brilliant nature of our own minds.{" "}
              </p>
              <br />
              <br />
            </div>
            <div>
              <a className="mintBtn2-M" href="/mint">
                Minting Live
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ESG;
