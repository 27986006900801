import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import React, { useState } from "react";
import "../Css/Header.css";

import FadeIn from "react-fade-in";

const Nav = () => {
  return (
    <ul className="navMenuM">
      <FadeIn>
        <li>
          <a href="/mint">Collection</a>
        </li>
        <br />
        <br />
        <br />
        <li>
          <a href="/about">About</a>
        </li>
        <br />
        <br />
        <br />
        <li>
          <a href="/members">Members</a>
        </li>
      </FadeIn>
    </ul>
  );
};

export default Nav;
