import React from "react";
import CGIY from "../img/CGIY.png";
import "../Css/Members.css";

const Members = () => {
  return (
    <div className="Members">
      <div className="Members-sec">
        <div className="cgiy2-img">
          <img className="cgiy2" src={CGIY} alt="" />
          <h1 className="commingSoon">Coming Soon</h1>
        </div>
      </div>
    </div>
  );
};
export default Members;
