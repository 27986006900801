import React from "react";
import MainCollection from "./Components/MainCollection";
import ESG from "./Components/ESG";
import Last from "./Components/Last";
import "./App.css";

const Home = () => {
  return (
    <div className="main">
      <div className="intro">
        <div className="intro-text">
          <h1>
            ESG CREATIVES
            <br />
            Our intention creates reality{" "}
          </h1>
          <br/>
          <p>
            ESG CREATIVES is a digital initiative bringing together the world's
            most influential pioneers <br/>with good intentions to create an ESG
            lifestyle and culture. Our mission is to elevate ESG <br/>lifestyle to
            the most creative medium to bring a positive impact to the world.
          </p>
          </div>
        </div>
      <div className="first-sec">
        <h1>HYA Collection</h1><br/>
        <h3>created by NFT (erc-721) </h3>
        <br />
        <br />
        <h1 style={{fontWeight: 200}}>
          <span>H</span> a p p i n e s s &nbsp; i s&nbsp; <span>Y</span> o u r  &nbsp;<span>A </span> c t i o n .
        </h1>
        <p>
          Happiness is not something ready made. It comes from your own actions -Dalai Lama
        </p>
        <br />
        <br />
        <p className="main-p">
          HYA is an art movement for the people, community, and the environment,
          also known as ESG; Environmental, Social, and Governance. ESG is an
          art that helps people to restore happiness. Powered by web3.
        </p>
        <br />
        <br />
        <a className="mintBtn" href="/mint">
          Minting Live
        </a>
        <br />
        <br />
      </div>
      <MainCollection />
      <ESG />
      {/* <Last /> */}
    </div>
  );
};
export default Home;
