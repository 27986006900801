import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";

import Genesis from "./Minting/Genesis";
import Gaia from "./Minting/Gaia";
import Sky from "./Minting/Sky";
import EcoHuman from "./Minting/EcoHuman";

// import "../Css/Mint.css";
// import "../App.css";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-family: "boorsok";
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: none;
  background-color: white;
  padding: 10px;
  padding-top: 15px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ff69f9 14.15%, #3decf8 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    background: linear-gradient(116.39deg, #3decf8 14.15%, #ff69f9 92.46%);
  }
`;
export const StyledButton2 = styled.button`
  // font-family: "boorsok";
  padding: 10px;
  font-size: 1.5rem;
  border-radius: 6px;
  border: 1px solid black;
  border: none;
  background-color: white;
  padding: 10px;
  padding-top: 15px;
  letter-spacing: 2px;
  font-weight: bold;
  color: white;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: rgba(0, 0, 0, 0.05);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border: 1px solid black;
  border-radius: 0px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    background: #728fa3;
  }
`;

export const CTNButton = styled.button`
  font-family: "boorsok";
  padding: 0px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-color: black;
  letter-spacing: 2px;
  font-weight: bold;
  color: black;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  // :hover {
  //   color: silver;
  // }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ffebff 14.15%, #fbffff 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    background: linear-gradient(116.39deg, #fbffff 14.15%, #ffebff 92.46%);
  }
`;

export const Maxbtn = styled.button`
  font-family: "coder";
  font-size: 0.75rem;
  border-radius: 10px;
  background-color: #f48c2c;
  font-weight: bold;
  color: white;
  width: 80px;
  height: 30px;
  cursor: pointer;
  letter-spacing: 2px;
  :hover {
    color: black;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 300px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  margin: auto;
  width: 40%;
  // border: 2px solid white;
  border-radius: 40px;
  background: rgb(243 243 252 / 70%);
  box-shadow: inset -6.60746px -10.5719px 29.0728px rgba(255, 255, 255, 0.25),
    inset 6.60746px 0px 22.4654px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    width: 80%;
  } ;
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  background-color: none;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 200px;
  @media (max-width: 767px) {
    width: 150px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 450px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 450px;
  }
  @media (min-width: 1000px) {
    width: 450px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const StyledLink = styled.a`
  font-family: "boorsok";
  color: rgb(32, 129, 226);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  padding-top: 5px;

  @media (max-width: 565px) {
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-align: center;
  }
`;

export const WalletBox = styled.div`
  // text-decoration: none;
  // border-radius: 10px;
  // border: 2px solid white;
  // background-color: transparent;
  // //padding: 10px;
  // font-weight: bold;
  // font-size: 15px;
  // width: 180px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-shadow: 0px 4px 0px -2px white;
  // -webkit-box-shadow: 0px 4px 0px -2px white;
  // -moz-box-shadow: 0px 4px 0px -2px white;
  // @media (max-width: 565px) {
  //   margin-top: 20px;

  // }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ffebff 14.15%, #fbffff 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;
  // @media (min-width: 566px) and (max-width: 766px) {
  //   margin: 0 auto;
  // }
`;

import "../Css/Collections.css";

const Collections = () => {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [DOT, setDOT] = useState("red");

  const [whitlisted, setWhitelisted] = useState(false);
  const [isMinted, setMinted] = useState(false);

  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topStart");
  const errmessage = (
    <Notification type={"error"} header={"error"} closable>
      Sorry, something went wrong please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={"success"} header={"success"} closable>
      Congrats, Mint Was successfull.
    </Notification>
  );
  const mntmessage = (
    <Notification type={"info"} header={"success"} closable>
      <Loader /> Minting in Progress....
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    MAX_PER_TX: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    Telegram: "",
    Discord: "",
    Twitter: "",
    SHOW_BACKGROUND: true,
  });

  // const whitelistForGenesis = async () => {
  //   const ret = await blockchain.smartContract.methods
  //     .whitelistForGenesis(blockchain.account)
  //     .call();
  //   console.log(`whitelistForGenesis : ${ret}`);
  //   setWhitelisted(ret);
  // };

  // const mintedForGenesis = async () => {
  //   const ret = await blockchain.smartContract.methods
  //     .mintedForGenesis(blockchain.account)
  //     .call();
  //   console.log(`mintedForGenesis : ${ret}`);
  //   setMinted(ret);
  // };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 5) +
          " . . . " +
          blockchain.account.substring(38, 42)
      );
      setDOT("green");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    // whitelistForGenesis();
    // mintedForGenesis();
  }, [blockchain.account]);

  return (
    <div className="Mining-Dapp">
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container ai={"center"} jc={"center"}>
          <StyledButton2
            className="MintBtn"
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            <h1>CONNECT</h1>
          </StyledButton2>
          {blockchain.errorMsg !== "" ? (
            <>
              <s.SpacerSmall />
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                  fontFamily: "coder",
                  fontSize: 20,
                }}
              >
                {blockchain.errorMsg}
              </s.TextDescription>
            </>
          ) : null}
        </s.Container>
      ) : (
        <div className="MintBoxAll">
          {/* <WalletBox>
              {blockchain.account !== "" ? (
                <>
                  <s.TextSubTitle>
                    <Badge color={DOT}/>
                    {walletAddress}
                  </s.TextSubTitle>
                </>
              ) : null}
            </WalletBox> */}<>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </>
          <div className="Mint-box-2">
            <div>
              <Genesis />
            </div>
            <div className="Mint-Text">
              <h1>GENESIS<br/>COLLECTION</h1><br/><br/>
              <p>Total : 99</p><br/><br/><br/><br/>
            </div>
          </div>



          <p>==========</p>



          <br /><br/><br/><br/><br/>
          <div className="Mint-box">
            <div className="Mint-Text">
              <h1>GAIA<br/>COLLECTION</h1>
              <p>Total : 900</p>
            </div>
            <div>
              <Gaia />
            </div>
          </div>



          <p>==========</p>



          <br />
          <div className="Mint-box-2">
            <div>
              <Sky />
            </div>
            <div className="Mint-Text-2">
              <h1>SKY<br/>COLLECTION</h1><br/><br/>
              <p>Total : 3000</p><br/><br/><br/><br />
            </div>
          </div>



          <p>==========</p>



          <br /><br /><br/><br/><br/>
          <div className="Mint-box">
            <div className="Mint-Text">
              <h1>ECO HUMAN<br/>COLLECTION</h1><br/><br/>
              <p>Total : 6000</p>
            </div>
            <div>
              <EcoHuman />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collections;
