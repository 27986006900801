import React, { useState, useEffect } from "react";

import mainImg from "../img/main.jpeg";
import "../Css/MainCollection.css";
import { Link } from "react-router-dom";

const MainCollection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  });

  return (
    <>
      <div className={scrollPosition < 1200 ? "MC" : "MC_end"}>
        <div className="MC-sec">
          <div className="MC-text-sec">
            <h1>HYA Collection and NFT</h1>
            <br />
            <p>
              Comprised 4 main collections with 4 ESG symbols that 99 artists
              create together; <m>G</m>enesis, <m>G</m>aia, <m>S</m>ky, and{" "}
              <m>E</m>co human collections.
              <br />
              <br />
              ​ HYA Collection Project was started by voluntary DAO members and
              artists collaborating with AI technology to elevate ESG to a
              next-generation digital art medium.
              <br />
              <br />
              {/* <span>
                Happiness is when what you think, what you say, and what you do
                are in harmony -Mahatma Gandhi-
              </span> */}
              Art is immortal so is the HYA collection archive in the
              blockchain. Blockchain technology is unstoppable and immutable
              because it runs on decentralized servers, so it gives immortality
              to the data that is being stored.
              <br />
              <br />
              Therefore, Erc -721 standard on the Ethereum blockchain, also
              known as NFT (Non-Fungible Token), was the suitable medium to
              archive our ESG creativity and pass it to the next generation.
              <br />
              <br />
              <a href="/about">Learn more about the collection</a>
            </p>
          </div>
          <div className="MC-image-sec">
            <img className="MC-img" src={mainImg} alt="" />
            {/* <p>GAIA collection #100</p> */}
          </div>
        </div>
      </div>
      {/* =============================768=========================== */}
      <div className={scrollPosition < 1400 ? "MC-M" : "MC_end-M"}>
        <div className="MC-sec-M">
          <div className="MC-image-sec-M">
            <img className="MC-img-M" src={mainImg} alt="png" />
            {/* <p>GAIA collection #100</p> */}
          </div>
          <br />
          <p>-</p>
          <br />

          <div className="MC-text-sec-M">
            <h1>HYA Collection and NFT</h1>
            <br />
            <p>
              <h6 style={{ color: "black" }}>
                Comprised 4 main collections with 4 ESG symbols that 99 artists
                create together; <br />
                <m>G</m>enesis, <m>G</m>aia, <m>S</m>ky, and <m>E</m>co human
                collections.
              </h6>
              <br />
              <br />
              ​ HYA Collection Project was started by voluntary DAO members and
              artists collaborating with AI technology to elevate ESG to a
              next-generation digital art medium. ​
              <br />
              <br />
              Art is immortal so is the HYA collection archive in the
              blockchain. Blockchain technology is unstoppable and immutable
              because it runs on decentralized servers, so it gives immortality
              to the data that is being stored.
              {/* <span>
                Happiness is when what you think, what you say, and what you do
                are in harmony -Mahatma Gandhi-
              </span> */}
              <br />
              Therefore, Erc -721 standard on the Ethereum blockchain, also
              known as NFT (Non-Fungible Token), was the suitable medium to
              archive our ESG creativity and pass it to the next generation.
              <br />
              <br />
              <a>
                <Link to="./about">Learn more about the collection</Link>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainCollection;
