import { createGlobalStyle } from 'styled-components'
import Cinzel from './fonts/Cinzel/static/Cinzel-Medium.ttf';
import TimesRoman from './fonts/OPTITimesRoman-Italic.otf';
// import Gumball from './Gumball.ttf';

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Cinzel';
        src: url(${Cinzel}) format('truetype');
    }

    @font-face {
        font-family: 'Cinzel';
        src: url(${Cinzel}) format('opentype');
    }

    @font-face {
        font-family: 'TimesRoman';
        src: url(${TimesRoman}) format('opentype');
    }
`;

// export const Renomono = createGlobalStyle`
//     @font-face {
//         font-family: 'Renomono';
//         src: url(${RenoMono}) format('opentype');
//     }
// `;

export default GlobalStyle;