import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import "rsuite/styles/index.less";
import "rsuite/dist/rsuite.min.css";
import { Panel, PanelGroup } from "rsuite";
import { Carousel } from "rsuite";
import { Notification, toaster } from "rsuite";
import { Loader } from "rsuite";
import { Badge } from "rsuite";

import "../../App.css";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  display: inline-block;
  padding: 0.7em 2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  transition: all 0.2s;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: #ffffff00;
  // box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
  //   inset -10px -16px 44px rgba(255, 255, 255, 0.25),
  //   inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border: 0.1em solid #ffffff;
  border-radius: 0px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    text-decoration: none;
    transition: background-color 0.2s linear;
    -webkit-transition: background-color 0.2s linear;
    color: #000000;
    background-color: #ffffff75;
  }
`;
export const StyledButton2 = styled.button`
  display: inline-block;
  padding: 0.7em 2em;
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  transition: all 0.2s;

  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: white;
  }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: #ffffff00;
  // box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
  //   inset -10px -16px 44px rgba(255, 255, 255, 0.25),
  //   inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border: 0.1em solid #ffffff;
  border-radius: 0px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    text-decoration: none;
    transition: background-color 0.2s linear;
    -webkit-transition: background-color 0.2s linear;
    color: #000000;
    background-color: #ffffff75;
  }
`;

export const CTNButton = styled.button`
  font-family: "boorsok";
  padding: 0px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-color: black;
  letter-spacing: 2px;
  font-weight: bold;
  color: black;
  width: 270px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px black;
  -webkit-box-shadow: 0px 6px 0px -2px black;
  -moz-box-shadow: 0px 6px 0px -2px black;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  // :hover {
  //   color: silver;
  // }
  @media (max-width: 565px) {
    width: 200px;
    height: 50px;
    font-size: 0.75rem;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ffebff 14.15%, #fbffff 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;

  :hover {
    background: linear-gradient(116.39deg, #fbffff 14.15%, #ffebff 92.46%);
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    color: silver;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10%;
  width: 300px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: center;
  margin: auto;
  width: 40%;
  // border: 2px solid white;
  border-radius: 40px;
  background: rgb(243 243 252 / 70%);
  box-shadow: inset -6.60746px -10.5719px 29.0728px rgba(255, 255, 255, 0.25),
    inset 6.60746px 0px 22.4654px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 767px) {
    width: 80%;
  } ;
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 80px;
  padding: 10px;
  background-color: none;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 565px) {
    max-height: 220px;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 200px;
  @media (max-width: 767px) {
    width: 150px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 450px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 450px;
  }
  @media (min-width: 1000px) {
    width: 450px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const Styledroad = styled.img`
  width: 100%;
  border-radius: 5px;
  transition: width 0.5s;
`;

export const StyledImgSmall = styled.img`
  width: 220px;
  height: 220px;
  border-radius: 5px;
  @media (min-width: 900px) {
    width: 220px;
    height: 220px;
  }
  @media (min-width: 1000px) {
    width: 220px;
    height: 220px;
  }
  transition: width 0.5s;
  @media (max-width: 565px) {
    width: 200px;
  }
`;

export const StyledLink = styled.a`
  font-family: "boorsok";
  color: rgb(32, 129, 226);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  padding-top: 5px;

  @media (max-width: 565px) {
    font-size: 0.75rem;
    letter-spacing: 2px;
    text-align: center;
  }
`;

export const WalletBox = styled.div`
  // text-decoration: none;
  // border-radius: 10px;
  // border: 2px solid white;
  // background-color: transparent;
  // //padding: 10px;
  // font-weight: bold;
  // font-size: 15px;
  // width: 180px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // box-shadow: 0px 4px 0px -2px white;
  // -webkit-box-shadow: 0px 4px 0px -2px white;
  // -moz-box-shadow: 0px 4px 0px -2px white;
  // @media (max-width: 565px) {
  //   margin-top: 20px;

  // }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 10px;

  width: 200px;
  height: 42px;

  background: linear-gradient(116.39deg, #ffebff 14.15%, #fbffff 92.46%);
  box-shadow: -10px -6px 14px rgba(255, 255, 255, 0.45), 10px 14px 24px #d4dced,
    inset -10px -16px 44px rgba(255, 255, 255, 0.25),
    inset 10px 0px 34px rgba(0, 0, 0, 0.05);
  border-radius: 33.2016px;

  flex: none;
  order: 3;
  flex-grow: 0;
  // @media (min-width: 566px) and (max-width: 766px) {
  //   margin: 0 auto;
  // }
`;

function Genesis() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [tokens, settokens] = useState(1);
  const [brd, setbrd] = useState("2px solid #F0F0F0F");
  const [bxsh, setbxsh] = useState("0px 0px 3px 0px #F0F0F0F");
  const [DOT, setDOT] = useState("red");

  const [whitlisted, setWhitelisted] = useState(false);
  const [isMinted, setMinted] = useState(false);

  const [type, setType] = React.useState("info");
  const [placement, setPlacement] = React.useState("topStart");
  const errmessage = (
    <Notification type={"error"} header={"error"} closable>
      Sorry, something went wrong please try again later.
    </Notification>
  );
  const txmessage = (
    <Notification type={"success"} header={"success"} closable>
      Congrats, Mint Was successfull.
    </Notification>
  );
  const mntmessage = (
    <Notification type={"info"} header={"success"} closable>
      <Loader /> Minting in Progress....
    </Notification>
  );
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    DISPLAY_COST: 0,
    GENESIS_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    MAX_PER_TX: 0,
    GENESIS_SUPPLY: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    Telegram: "",
    Discord: "",
    Twitter: "",
    SHOW_BACKGROUND: true,
  });

  const claimNFTs = () => {
    let cost = CONFIG.GENESIS_COST * tokens;
    let price = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", price);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    setbrd("2px solid #F0F0F0F");
    setbxsh("0px 0px 3px 0px #F0F0F0F");
    toaster.push(mntmessage, { placement });
    blockchain.smartContract.methods
      .mintForGenesis(tokens)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: price,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
        toaster.push(errmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        toaster.push(txmessage, { placement });
        setbrd("2px solid #F0F0F0F");
        setbxsh("0px 0px 0px 0px #F0F0F0F");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementtokens = () => {
    let newtokens = tokens - 1;
    if (newtokens < 1) {
      newtokens = 1;
    }
    settokens(newtokens);
  };

  const incrementtokens = () => {
    let newtokens = tokens + 1;
    if (newtokens > CONFIG.MAX_PER_TX) {
      newtokens = CONFIG.MAX_PER_TX;
    }
    settokens(newtokens);
  };

  const whitelistForGenesis = async () => {
    const ret = await blockchain.smartContract.methods
      .whitelistForGenesis(blockchain.account)
      .call();
    console.log(`whitelistForGenesis : ${ret}`);
    setWhitelisted(ret);
  };

  const mintedForGenesis = async () => {
    const ret = await blockchain.smartContract.methods
      .mintedForGenesis(blockchain.account)
      .call();
    console.log(`mintedForGenesis : ${ret}`);
    setMinted(ret);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setAddress(
        blockchain.account.substring(0, 5) +
          " . . . " +
          blockchain.account.substring(38, 42)
      );
      setDOT("green");
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    whitelistForGenesis();
    mintedForGenesis();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {/* <WalletBox>
              {blockchain.account !== "" ? (
                <>
                  <s.TextSubTitle>
                    <Badge color={DOT}/>
                    {walletAddress}
                  </s.TextSubTitle>
                </>
              ) : null}
            </WalletBox> */}
      <s.SpacerSmall />
      <s.Container flex={1} jc={"center"} ai={"center"}>
        {Number(data.totalSupply) >= CONFIG.GENESIS_SUPPLY ? (
          <>
            <StyledButton2 disabled={whitlisted == false} onClick={null}>
              {claimingNft ? (
                <Loader speed="fast" content="Minting..." />
              ) : (
                "Mint Out"
              )}
            </StyledButton2>
            {/* <s.SpacerSmall />
            <s.TextSubTitle style={{ textAlign: "center", fontSize: "2rem" }}>
              SOLD OUT!
            </s.TextSubTitle>
            <s.SpacerSmall />
            <StyledLink href={CONFIG.MARKETPLACE_LINK}>
              GO {CONFIG.MARKETPLACE}
            </StyledLink> */}
          </>
        ) : (
          <>
            <s.SpacerLarge />
            {/* <img
                  className="main-png"
                  alt={"png"}
                  src={"/config/images/1.gif"}
                /> */}
            {blockchain.account === "" ||
            blockchain.smartContract === null ? null : (
              <>
                <s.SpacerSmall />

                <s.TextSub
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontFamily: "boorsok",
                  }}
                >
                  {data.totalSupply} | {CONFIG.GENESIS_SUPPLY}
                </s.TextSub>

                {whitlisted == true ? (
                  <>
                    {/* <s.AmountContainer
                      style={{
                        border: brd,
                        boxShadow: bxsh,
                      }}
                    >
                      <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementtokens();
                            }}
                          >
                            <img
                              className="amountBTN"
                              alt={"png"}
                              src={"/config/images/-.png"}
                            />
                          </StyledRoundButton>
                      <s.TEXTamount>
                        &ensp;&ensp;&ensp;&ensp; {tokens}&ensp;&ensp;&ensp;
                      </s.TEXTamount>
                      <s.SpacerMedium />
                      <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementtokens();
                            }}
                          >
                            <img
                              className="amountBTN"
                              alt={"png"}
                              src={"/config/images/+.png"}
                            />
                          </StyledRoundButton>
                    </s.AmountContainer> */}
                    <s.SpacerSmall />

                    {isMinted < 1 ? (
                      <>
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINT NFT"
                            )}
                          </StyledButton>
                        </s.Container>
                        <s.SpacerSmall />

                        {/* <s.TextTotal style={{ textAlign: "center", fontSize: 15, color: "white" }}>
                              can Mint <span style={{ color: "#FF69F9" }}>{3 - isMinted}</span> more
                              Genesis Collection
                            </s.TextTotal> */}
                      </>
                    ) : (
                      <>
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          <StyledButton2
                            disabled={isMinted >= 3}
                            onClick={null}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINTED"
                            )}
                          </StyledButton2>
                        </s.Container>

                        <StyledLink href={CONFIG.COLLECTION_LINK}>
                          GO {CONFIG.MARKETPLACE}
                        </StyledLink>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <s.SpacerSmall />
                    {/* <s.AmountContainer
                      style={{
                        border: brd,
                        boxShadow: bxsh,
                      }}
                    >
                      <StyledRoundButton
                            style={{ lineHeight: 0.4 }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementtokens();
                            }}
                          >
                            <img
                              className="amountBTN"
                              alt={"png"}
                              src={"/config/images/-.png"}
                            />
                          </StyledRoundButton>
                      <s.TEXTamount>
                        &ensp;&ensp;&ensp;&ensp; {tokens}&ensp;&ensp;&ensp;
                      </s.TEXTamount>
                      <s.SpacerMedium />
                      <StyledRoundButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementtokens();
                            }}
                          >
                            <img
                              className="amountBTN"
                              alt={"png"}
                              src={"/config/images/+.png"}
                            />
                          </StyledRoundButton>
                    </s.AmountContainer> */}
                    <s.SpacerSmall />

                    {isMinted < 1 ? (
                      <>
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          {/* <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              {claimingNft ? (
                                <Loader speed="fast" content="Minting..." />
                              ) : (
                                "MINT NFT"
                              )}
                            </StyledButton> */}

                          <StyledButton2
                            disabled={whitlisted == false}
                            onClick={null}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "Mint Out"
                            )}
                          </StyledButton2>
                        </s.Container>
                        {/* <s.TextTotal style={{ textAlign: "center", fontSize: 15, color: "white" }}>
                              can Mint <span style={{ color: "#FF69F9" }}>{3 - isMinted}</span> more
                              Genesis Collection
                            </s.TextTotal> */}
                      </>
                    ) : (
                      <>
                        <s.Container ai={"center"} jc={"center"} fd={"column"}>
                          <StyledButton2
                            disabled={isMinted >= 3}
                            onClick={null}
                          >
                            {claimingNft ? (
                              <Loader speed="fast" content="Minting..." />
                            ) : (
                              "MINTED"
                            )}
                          </StyledButton2>
                        </s.Container>
                        <StyledLink href={CONFIG.COLLECTION_LINK}>
                          GO {CONFIG.MARKETPLACE}
                        </StyledLink>
                      </>
                    )}
                  </>
                )}
                <s.TextSubTitle
                  style={{ textAlign: "center", fontSize: "1rem" }}
                >
                  {feedback}
                </s.TextSubTitle>
              </>
            )}
          </>
        )}
      </s.Container>
      {/* <s.SecContainer id="">
          <s.socialDiv>
            <a href={CONFIG.Telegram} target={"_blank"}>
              <s.Icons src="/config/images/telegram.svg" alt="telegram" />
            </a>
            <a href={CONFIG.Discord} target={"_blank"}>
              <s.Icons src="/config/images/dis.svg" alt="discord" />
            </a>
            <a href={CONFIG.Twitter} target={"_blank"}>
              <s.Icons src="/config/images/twi.svg" alt="twitter" />
            </a>
            <a href={CONFIG.MARKETPLACE_LINK} target={"_blank"}>
              <s.Icons src="/config/images/opensea.svg" alt="opensea" />
            </a>
          </s.socialDiv>
        </s.SecContainer> */}
    </s.Screen>
  );
}

export default Genesis;
