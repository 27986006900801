import React, { useState } from "react";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";

import Nav from "./Components/Nav";
import Home from "./Home";
import About from "./Components/About";
import Members from "./Components/Members";
import Collections from "./Components/Collections";

import "./App.css";
import "./Css/Header.css";

const App = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <BrowserRouter>
      <header>
        <nav className="navBar">
          <a>
            <a className="logoText" href="/">
              ESG Creatives
            </a>
          </a>
          <ul className="navMenu">
            <li>
              <Link className="menuBtn" to="/mint">
                Collection
              </Link>
            </li>
            {/* <li></li>
              <Link to="/about">About</Link>
            </li> */}
            <li>
              <Link to="/members">Members</Link>
            </li>
          </ul>

          <div className="hamburger">
            <Hamburger
              size={25}
              duration={0.8}
              label="Show navMenuM"
              toggled={isOpen}
              toggle={setOpen}
              color="rgb(208, 208, 208)"
            />
            {isOpen ? <Nav /> : null}
          </div>
        </nav>
      </header>

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/mint" element={<Collections />} />
        <Route path="/members" element={<Members />} />
        <Route path="/*" element={<h2>not found</h2>} />
      </Routes>

      <div className="footer">
        <div className="footer-box">
          <div className="footer-text">
          <h1>ESG CREATIVES </h1>
          <br/><br/>
            <p>Our intention creates reality</p>
            <br />
            <p>
              The world’s most influential pioneers,
              <br />
              committed to ESG lifestyle and culture
            </p>
          </div>

          <div className="footer-link-box">
            <div>
            <p>Contact us for</p><br/>
              <p>Artist apply </p>
              <p>member apply </p>
            </div>
            <div>
              <p>&emsp;</p><br/>
              <p>Terms and</p>
              <p>conditions</p>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};
export default App;
